import React from "react";
import Meta from "components/shared/Meta";
import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";
import CTA from "components/shared/CTA";
import LargeTitle from "components/services/LargeTitle";
import icon from "images/svg_icons/cloud2.svg";
import { StaticImage } from "gatsby-plugin-image";
import ImageBlock from "components/services/ImageBlock";
import HostingGrid from "components/services/HostingGrid";

export default () => (
  <>
    <Meta url="/services/hosting/" />
    <Nav />
    <LargeTitle
      img={icon}
      green="Hosting"
      black="Services"
      text="AppInChina's China-side hosting solution secures your app, SaaS, PaaS, and game data on servers physically on Chinese soil, providing an easy way to comply with local laws."
    />
    <ImageBlock
      title="Local hosting made easy."
      text={
        <>
          <p>
            China Cybersecurity Law requires personally identifiable information
            (PII) and important data of the critical information infrastructure
            (CII) operator to be stored domestically.
          </p>
          <p>
            Our local hosting solution is fully compliant and gives your Chinese
            users a fast experience with no lag or downtime. We also provide
            alternatives to popular SDKs that don't work in China, such as
            Firebase.
          </p>
        </>
      }
      img={
        <StaticImage
          src="../../images/photos/servers.jpg"
          alt="Hosting"
          placeholder="blurred"
          layout="fixed"
          width={800}
          height={650}
        />
      }
    />
    <HostingGrid />
    <CTA />
    <Footer />
  </>
);
