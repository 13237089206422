import React from "react";
import * as classes from "./index.module.less";
import Layout from "components/shared/Layout";
import Card from "components/shared/Card";
import servers from "images/svg_icons/servers.svg";
import gdpr from "images/svg_icons/gdpr.svg";
import cloud from "images/svg_icons/cloud3.svg";

export default () => (
  <div className={classes.container}>
    <Layout>
      <h3>Our Localization Solutions</h3>
      <div className={classes.cards}>
        <Card
          title="Cloud Services"
          href="/services/localization/cloud-services/"
          align="verticalreverse"
          content={
            <p>
              Learn your various options for cloud hosting in China, and how we
              can help your developers integrate the solution that is right for
              you.
            </p>
          }
          img={cloud}
        />
        <Card
          title="Parse Server for China"
          href="/services/hosting/parse-server-firebase-alternative-for-china/"
          content={
            <p>
              Firebase does not work in China, but the perfect Firebase
              alternative is a Parse Server that is hosted locally. We can set
              you up.
            </p>
          }
          img={servers}
        />
        <Card
          title="Legal Compliance"
          href="/services/localization/compliance/"
          content={
            <p>
              Though Chinese data law is changing constantly, our Beijing-based
              team always stays on top of the current rules & regulations so you
              don't have to.
            </p>
          }
          img={gdpr}
        />
      </div>
    </Layout>
  </div>
);
